import { get, post } from '@/utils/http.js';

/*
function:获取學籍信息(没有token)
author:sjy
time:2021/03/16
*/

export function getStudentDate(data: any) {
    return get('/student/mobile/management', data);
}

/*
function:转换值(没有token)
author:sjy
time:2021/03/18
*/
export function getKeyList(data: any) {
    return post('/assets/transition/GET', data);
}

/*
function:获取院校和专业的转换值(没有token)
author:sjy
time:2021/03/18
*/
export function getTblInfo(data: any) {
    return post('/assets/table/info', data);
}
