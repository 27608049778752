












































































































import { Component, Vue } from 'vue-property-decorator';
import { getStudentDate, getKeyList, getTblInfo } from '@/api/myStudentStatus/myStudentStatus.ts';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class About extends Vue {
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      semester_id: 0
  };

  loading = true;
  studentDate = [];
  // 转换值
  keyList = [];
  nations = '';
  studyModes = '';
  studyLevels = '';
  academics = '';
  studentStatus = '';
  orgHash = '';
  majorHash = '';
  userImg = '';

  created() {
      const student = JSON.parse(localStorage.getItem('student') as string);
      this.userImg = student.user_img;

      this.getData();
      this.getKeyList();
      this.getTblInfo();
  }

  // 方法
  getData() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      getStudentDate(this.data).then((res: any) => {
          this.studentDate = res.data[0];
          this.loading = false;
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 获取转换值
  getKeyList() {
      const data = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          key_list: ['academics', 'study_levels', 'study_modes', 'politics', 'nations', 'student_status']
      };
      getKeyList(data).then((res: any) => {
          if (res.code === 200) {
              this.keyList = res.data;
              // 具体值
              this.nations = res.data.nations[1];
              this.studyModes = res.data.study_modes[1];
              this.studyLevels = res.data.study_levels[1];
              this.academics = res.data.academics[1];
              this.studentStatus = res.data.student_status[1];
          }
      })
          .catch((error: any) => {
              console.log(error);
          });
  }

  getTblInfo() {
      const data = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          tbl_list: [['org', 'org_name', 'academy_base_t'], ['id', 'name', 'major_base_t']],
          state: 1
      };
      getTblInfo(data).then((res: any) => {
          if (res.code === 200) {
              this.orgHash = res.data[0][1];
              this.majorHash = res.data[1][1];
              // 具体值
          }
      })
          .catch((error: any) => {
              console.log(error);
          });
  }
}
